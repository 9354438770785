import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import { PrimeReactProvider } from 'primereact/api';
import './App.css';

import Layout from './Layout';
import Login from './pages/auth/login';
import ResetPassword from './pages/auth/reset-password';
import CreateNewPassword from './pages/auth/new-password';
import SuccessResetPassword from './pages/auth/success-reset-password';
import SuccessSentEmail from './pages/auth/success-sent-email';

import Invites from './pages/invites';

import Users from './pages/users';
import AddNewUser from './pages/users/add-new';
import SingleUser from './pages/users/single-user';
import EditUserDetail from './pages/users/edit-user-detail';
import EditUserPreferences from './pages/users/edit-user-preferences';
import SignleUserEvent from './pages/users/single-event';

import Events from './pages/events';
import AddNewEvent from './pages/events/addNew';
import AddNewAct from './pages/events/addNewAct';
import SingleEvent from './pages/events/single-event';
import EditEventDetail from './pages/events/edit-event-detail';
import EditAct from './pages/events/edit-act';
import EditItinerary from './pages/events/edit-itinerary';

import Explore from './pages/explore';
import AddNewExplore from './pages/explore/addNew';

import Account from './pages/account';

import 'primereact/resources/primereact.css';
import 'primereact/resources/themes/lara-light-indigo/theme.css';

import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file

const router = createBrowserRouter([
    {
        path: "/",
        element: <Layout />,
        children: [
            {
                path: "invites",
                element: <Invites />,
            },
            {
                path: "users",
                element: <Users />,
            },
            {
                path: "users/add-new",
                element: <AddNewUser />,
            },
            {
                path: "users/:id",
                element: <SingleUser />,
            },
            {
                path: "users/:id/edit",
                element: <EditUserDetail />,
            },
            {
                path: "users/:id/edit-perferences",
                element: <EditUserPreferences />,
            },
            {
                path: "users/:id/events/:eventId",
                element: <SignleUserEvent />,
            },
            {
                path: "events",
                element: <Events />,
            },
            {
                path: "events/add-new",
                element: <AddNewEvent />,
            },
            {
                path: "events/add-new-act",
                element: <AddNewAct />,
            },
            {
                path: "events/:id",
                element: <SingleEvent />,
            },
            {
                path: "events/:id/edit",
                element: <EditEventDetail />,
            },
            {
                path: "events/:id/edit-act/:actId",
                element: <EditAct />,
            },
            {
                path: "events/:id/edit-itinerary/:itineraryIndex",
                element: <EditItinerary />,
            },
            {
                path: "explore",
                element: <Explore />,
            },
            {
                path: "explore/add-new",
                element: <AddNewExplore />,
            },
            {
                path: "explore/:id/edit",
                element: <AddNewExplore />,
            },
            {
                path: "account",
                element: <Account />,
            },
        ]
    },
    {
        path: "login",
        element: <Login />,
    },
    {
        path: "reset-password",
        element: <ResetPassword />,
    },
    {
        path: "new-password/:token",
        element: <CreateNewPassword />,
    },
    {
        path: "success-reset-password",
        element: <SuccessResetPassword />,
    },
    {
        path: "success-sent-email",
        element: <SuccessSentEmail />,
    },
]);





function App() {

    return (
        <PrimeReactProvider value={{ unstyled: false }}>
            <RouterProvider router={router} />
        </PrimeReactProvider>
    );
}

export default App;
