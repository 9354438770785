export default function setupAxios(axios, store) {
    axios.defaults.baseURL = process.env.REACT_APP_API_URL;
    axios.defaults.withCredentials = true;

    axios.interceptors.request.use(
        (config) => {
            const { auth: { authToken } } = store.getState();

            if (authToken) {
                config.headers.Authorization = `Bearer ${authToken}`;
            }
            return config;
        },
        (err) => Promise.reject(err)
    );
    axios.interceptors.response.use(
        ({ data }) => data,
        (err) => {
            if (err.response && err.response.status === 401) {
                window.location = ("/");
                localStorage.clear();
            } else {
                return Promise.reject(err);
            }
        }
    );
}