import { Link } from 'react-router-dom';

export default function VoxA({label, onClick, className, to})
{   
    console.log(to)
    return (
        <Link 
            className={`text-[#897151] text-xs leading-[14px] font-medium ${className} cursor-pointer`}
            // onClick={onClick}
            to={to}
        >
            {label}
        </Link>
    )
}