import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import axios from "axios";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { useNavigate } from "react-router-dom";

import { setTitle, setTo } from "../../store/featrues/data/dataSlice";
import { setLoading } from "../../store/featrues/auth/authSlice";

import VoxBorderButton from "../../components/VoxBorderButton";

export default function Events() {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [ events, setEvents ] = useState([]);

    // Define options for formatting
    const dateFormatOptions = { year: 'numeric', month: 'long' };

    useEffect(() => {
        dispatch(setTitle("Events"))
        dispatch(setTo('/events/add-new'))
        dispatch(setLoading(true))

        const fetchEvents = async() => {
            try {
                const { data } = await axios.get('events')
                console.log(data)
                setEvents(data)
                dispatch(setLoading(false))
            } catch (error) {
                dispatch(setLoading(false))
            }
        }

        fetchEvents()
    }, [])


    
    return (
        <div className="bg-[#18140c] flex flex-col gap-5 w-full rounded-lg py-5">
            <div className="w-full text-white px-5 justify-between font-quichesans font-normal text-xl">
                {`Events`}
            </div>

            <DataTable value={ events } tableStyle={{ minWidth: '50rem' }}>
                <Column field="location" header="Event"></Column>
                <Column header="Date" body={ (row) => new Intl.DateTimeFormat('en-US', dateFormatOptions).format(new Date(row.date[0].startDate)) }></Column>
                <Column header="ID" body={ (row) => `${row.id}`.padStart(5, '0') }></Column>
                <Column header="" body={ (row) => (<div className="flex gap-2 justify-end"><VoxBorderButton label={'View'} onClick={() => navigate(`/events/${row.id}`)} /></div>) } ></Column>
            </DataTable>
        </div>
    )
}