import { Link } from "react-router-dom"

export default function VoxButton({ label, onClick, className = '', type = 'button', to = '' }) {
    return (
        to ? (
            <Link
                className={`bg-[#897151] text-white font-normal text-sm py-[15px] px-[30px] rounded-[100px] ${className}`}
                to={to}
            >
                {label}
            </Link>
        ) : (
        <button
            className={`bg-[#897151] text-white font-normal text-sm py-[15px] px-[30px] rounded-[100px] ${className}`}
            onClick={onClick}
            type={type}
        >
            {label}
        </button>
    )
)
}