import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import axios from "axios";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { useNavigate } from "react-router-dom";
import { Dropdown } from 'primereact/dropdown';

import { setTitle, setTo } from "../../store/featrues/data/dataSlice";
import { setLoading } from "../../store/featrues/auth/authSlice";

import VoxBorderButton from "../../components/VoxBorderButton";

export default function Users() {

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [users, setUsers] = useState([])
    const [selectedRole, setSelectedRole] = useState(null);

    const roles = [
        { name: 'Guest' },
        { name: 'Admin' },
    ];


    useEffect(() => {
        dispatch(setTitle("Users"))
        dispatch(setTo('/users/add-new'))
        dispatch(setLoading(true))

        const fetchUsers = async (role) => {
            try {
                const { data } = await axios.get('users', {
                    params: {
                        role: role
                    }
                })
                setUsers(data)
                dispatch(setLoading(false))
            } catch (error) {
                dispatch(setLoading(false))
            }
        }

        fetchUsers(selectedRole?.name)
    }, [dispatch, selectedRole])


    
    const handleItemClick = (name) => {
        if( selectedRole?.name === name ) {
            setSelectedRole(null)
        } else {
            setSelectedRole({name})
        }
    };

    const itemTemplate = (option) => {
        return (
            <div onClick={() => handleItemClick(option.name)} className="p-dropdown-custom-label">
                {option.name}
            </div>
        );
    };

    return (
        <div className="bg-[#18140c] flex flex-col gap-5 w-full rounded-lg py-5">
            <div className="w-full text-white px-5 font-quichesans font-normal text-xl flex justify-between items-center">
                {`Users`}

                <Dropdown 
                    value={selectedRole} 
                    itemTemplate={itemTemplate}
                    options={roles} 
                    optionLabel="name" 
                    placeholder="Filter by Role" 
                    className="bg-transparent border rounded-[100px] border-[#5B5549] !shadow-none [&>.p-dropdown-label]:bg-transparent [&>.p-dropdown-label]:text-white [&>.p-dropdown-label]:text-base [&>.p-dropdown-label]:font-normal [&>.p-dropdown-label]:pl-5" 
                />
            </div>

            <DataTable value={users} tableStyle={{ minWidth: '50rem' }}>
                <Column body={(row) => `${row.name} ${row.lname || ""}`} header="Name"></Column>
                <Column field="role" header="Role"></Column>
                <Column body={(row) => `${row.id}`.padStart(5, '0')} header="ID"></Column>
                <Column header="" body={(row) => (<div className="flex gap-2 justify-end"><VoxBorderButton label={'View'} onClick={() => navigate(`/users/${row.id}`)} /></div>)} ></Column>
            </DataTable>
        </div>
    );
}