import { configureStore } from '@reduxjs/toolkit'

import authReducer from '../store/featrues/auth/authSlice'
import dataReducer from '../store/featrues/data/dataSlice'

export default configureStore({
    reducer: {
        auth: authReducer, 
        data: dataReducer
    },
})