import { useState } from 'react';
import axios from 'axios';
import { Card } from 'primereact/card';
import { useNavigate } from 'react-router-dom';

import VoxInput from '../../components/VoxInput';
import VoxButton from '../../components/VoxButton';

import logo from "../../assets/svg/logo-l.svg";
import logoS from "../../assets/svg/logo-s.svg";

export default function ResetPassword() {

    const [email, setEmail] = useState("")
    const [message, setMessage] = useState("")
    const navigate = useNavigate()

    const handleForgotPassword = async () => {
        setMessage("")
        try {
            await axios.post('auth/forgot-password', {
                email
            })
            setEmail("")
            navigate('/success-sent-email')
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <div className='min-h-[100vh] w-full flex justify-center items-center bg-[#070603] py-10'>
            <Card className='bg-[#18140c] lg:px-[120px] lg:py-[166px] sm:px-[60px] sm:py-[30px] px-[20px] py-[40px] rounded-lg '>
                <div className='flex flex-col gap-[60px] lg:gap-[275px] lg:flex-row sm:px-[126px] sm:pb-[80px] sm:lg:px-0 lg:pb-0'>
                    <div className='flex flex-col gap-8 sm:w-[335px] w-[295px]'>
                        <div className='flex flex-col gap-5'>
                            <div className='header4 text-[25px] leading-[31px]'>Reset Password</div>
                            <div className='content4 text-base'>Enter your email address to reset your password. You will receive an email with instructions on how to reset it. </div>
                        </div>
                        <div className='flex flex-col gap-5'>
                            <VoxInput
                                placeholder="EMAIL ADDRESS"
                                classsName='w-full'
                                name="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                            {
                                message && <div className='text-green-500'>{message}</div>
                            }
                        </div>

                        <div className='flex flex-col gap-5'>
                            <VoxButton label='Reset Password' onClick={handleForgotPassword} />
                        </div>
                    </div>

                    <img src={logo} alt="logo-image" className='hidden sm:block' />
                    <img src={logoS} alt="logo-image" className='sm:hidden block' />
                </div>
            </Card>
        </div>
    )
}