import { useEffect, useState } from 'react'
import axios from 'axios'
import { useParams, useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import VoxBorderButton from '../../components/VoxBorderButton'
import VoxDeleteModal from '../../components/VoxDeleteModal';
import { setLoading } from '../../store/featrues/auth/authSlice'
import { setTo, setTitle } from '../../store/featrues/data/dataSlice'

// Define options for formatting
const dateFormatOptions = { year: 'numeric', month: 'long' };

export default function SingleUser() {

    const [user, setUser] = useState(null)
    const { id } = useParams()
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [events, setEvents] = useState([])
    const [modalOpen, setModalOpen] = useState(false)
    const [eventSearch, setEventSearch] = useState('')

    useEffect(() => {
        dispatch(setTitle("Users"))
        dispatch(setTo(""))
        dispatch(setLoading(true))
        const fetchUser = async (id) => {
            try {
                const { data } = await axios.get(`users/${id}`);
                setUser(data)
                const response = await axios.get('events')
                setEvents(response?.data)
                dispatch(setLoading(false))
            } catch (error) {
                dispatch(setLoading(false))
            }
        }

        fetchUser(id)
    }, [dispatch, id])

    const handleConfirmDelete = async () => {
        try {
            await axios.delete(`users/${id}`)
            setModalOpen(false)
            navigate('/users')
        } catch (error) {
            console.log(error)
        }
    }



    return (
        <div className="w-full flex flex-col gap-8">
            <div className="bg-[#18140c] flex flex-col gap-10 w-full rounded-lg items-center px-9 py-8">
                <div className="flex justify-between w-full items-center">
                    <div className="text-white font-quichesans font-normal text-2xl">Details</div>
                    <VoxBorderButton label={'Edit'} onClick={() => navigate(`/users/${id}/edit`)} />
                </div>

                <div className='grid grid-cols-1 md:grid-cols-3 w-full gap-5'>
                    <div className='flex flex-col gap-[10px]'>
                        <div className='uppercase text-xs text-[#897151]'>PROFILE PICTURE</div>
                        {
                            user?.image ?
                                <img src={`${process.env.REACT_APP_STORAGE_URL}${user?.image}`} className='w-[50px] h-[50px] rounded-full border border-[#897151]' alt='' />
                                :
                                <div className='w-[50px] h-[50px] rounded-full border border-[#897151]'></div>
                        }
                    </div>

                    <div className='flex flex-col gap-[10px]'>
                        <div className='uppercase text-xs text-[#897151]'>name</div>
                        <div className='text-white text-base font-normal font-generalsans leading-6'>{`${user?.name} ${user?.lname || ""}`}</div>
                    </div>

                    <div className='flex flex-col gap-[10px]'>
                        <div className='uppercase text-xs text-[#897151]'>Age/Birthday</div>
                        <div className='text-white text-base font-normal font-generalsans leading-6'>{calculateAge(user?.birthday)}</div>
                    </div>

                    <div className='flex flex-col gap-[10px]'>
                        <div className='uppercase text-xs text-[#897151]'>Phone</div>
                        <div className='text-white text-base font-normal font-generalsans leading-6'>{user?.phone || "NA"}</div>
                    </div>

                    <div className='flex flex-col gap-[10px]'>
                        <div className='uppercase text-xs text-[#897151]'>EMAIL ADDRESS</div>
                        <div className='text-white text-base font-normal font-generalsans leading-6'>{user?.email}</div>
                    </div>

                    <div className='flex flex-col gap-[10px]'>
                        <div className='uppercase text-xs text-[#897151]'>ROLE</div>
                        <div className='text-white text-base font-normal font-generalsans leading-6'>{user?.role}</div>
                    </div>
                </div>
            </div>


            <div className="bg-[#18140c] flex flex-col gap-10 w-full rounded-lg items-center px-9 py-8">
                <div className="flex justify-between w-full items-center">
                    <div className="text-white font-quichesans font-normal text-2xl">Preferences</div>
                    <VoxBorderButton label={'Edit'} onClick={() => navigate(`/users/${id}/edit-perferences`)} />
                </div>

                <div className='flex flex-col gap-10 w-full'>
                    <div className='grid grid-cols-1 md:grid-cols-4 w-full gap-5'>
                        <div className='md:col-span-4 text-white font-quichesans font-normal text-xl'>Allergies & Restrictions</div>

                        <div className='flex flex-col gap-[10px]'>
                            <div className='uppercase text-xs text-[#897151]'>allergies</div>
                            <div className='text-white text-base font-normal font-generalsans leading-6'>{`${user?.allergies || "NA"}`}</div>
                        </div>

                        <div className='flex flex-col gap-[10px]'>
                            <div className='uppercase text-xs text-[#897151]'>intolerances</div>
                            <div className='text-white text-base font-normal font-generalsans leading-6'>{`${user?.intolerances || "NA"}`}</div>
                        </div>

                        <div className='flex flex-col gap-[10px]'>
                            <div className='uppercase text-xs text-[#897151]'>other allergies/intolerances</div>
                            <div className='text-white text-base font-normal font-generalsans leading-6'>{user?.other_allergies_intolerances || "NA"}</div>
                        </div>

                        <div className='flex flex-col gap-[10px]'>
                            <div className='uppercase text-xs text-[#897151]'>Religious Food Restrictions</div>
                            <div className='text-white text-base font-normal font-generalsans leading-6'>{user?.religious_food_restrictions || "NA"}</div>
                        </div>
                    </div>

                    <div className='grid grid-cols-1 md:grid-cols-4 w-full gap-5'>
                        <div className='md:col-span-4 text-white font-quichesans font-normal text-xl'>Food & Drink</div>

                        <div className='flex flex-col gap-[10px]'>
                            <div className='uppercase text-xs text-[#897151]'>Favourite Foods</div>
                            <div className='text-white text-base font-normal font-generalsans leading-6'>{`${user?.favourite_foods || "NA"}`}</div>
                        </div>

                        <div className='flex flex-col gap-[10px]'>
                            <div className='uppercase text-xs text-[#897151]'>Food Dislikes</div>
                            <div className='text-white text-base font-normal font-generalsans leading-6'>{`${user?.food_dislikes || "NA"}`}</div>
                        </div>

                        <div className='flex flex-col gap-[10px]'>
                            <div className='uppercase text-xs text-[#897151]'>Favourite Soft Drinks</div>
                            <div className='text-white text-base font-normal font-generalsans leading-6'>{user?.favourite_soft_drinks || "NA"}</div>
                        </div>

                        <div className='flex flex-col gap-[10px]'>
                            <div className='uppercase text-xs text-[#897151]'>Favourite Alcoholic Drink/cocktail</div>
                            <div className='text-white text-base font-normal font-generalsans leading-6'>{user?.favourite_alcoholic_drink_cocktail || "NA"}</div>
                        </div>
                    </div>

                    <div className='grid grid-cols-1 md:grid-cols-4 w-full gap-5'>
                        <div className='md:col-span-4 text-white font-quichesans font-normal text-xl'>Any other information we can be aware of to ensure your experience is seamless?</div>

                        <div className='flex flex-col gap-[10px]'>
                            <div className='uppercase text-xs text-[#897151]'>Specific Food/Beverage Requests</div>
                            <div className='text-white text-base font-normal font-generalsans leading-6'>{`${user?.specific_food_beverage_requests || "NA"}`}</div>
                        </div>

                        <div className='flex flex-col gap-[10px]'>
                            <div className='uppercase text-xs text-[#897151]'>Specific Room Requests</div>
                            <div className='text-white text-base font-normal font-generalsans leading-6'>{`${user?.specific_room_requests || "NA"}`}</div>
                        </div>

                        <div className='flex flex-col gap-[10px]'>
                            <div className='uppercase text-xs text-[#897151]'>Additional Information</div>
                            <div className='text-white text-base font-normal font-generalsans leading-6'>{user?.additional_information || "NA"}</div>
                        </div>

                    </div>

                </div>

            </div>

            <div className="bg-[#18140c] flex flex-col gap-10 w-full rounded-lg items-center px-9 py-8">
                <div className="flex justify-between w-full items-center">
                    <div className="text-xl text-white font-normal font-quichesans">Events</div>
                </div>

                <div className="w-full h-[58px] border-[#5B5549] border rounded-md flex items-center px-5">
                    <input
                        className="bg-transparent w-full text-sm font-normal text-white outline-none font-generalsans"
                        placeholder="Assign Event"
                        value={eventSearch}
                        onChange={(e) => setEventSearch(e.target.value)}
                    />
                    <svg width="19" height="20" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M18.8905 18.7439L13.9499 13.8033C15.3346 12.2801 16.0687 10.2757 15.9952 8.2184C15.9217 6.16114 15.0465 4.21421 13.5566 2.79364C12.0668 1.37308 10.0804 0.591538 8.02199 0.616038C5.96356 0.640538 3.99635 1.46913 2.54073 2.92476C1.0851 4.38039 0.256504 6.3476 0.232004 8.40602C0.207504 10.4644 0.989042 12.4508 2.40961 13.9407C3.83018 15.4305 5.77711 16.3057 7.83437 16.3792C9.89163 16.4527 11.896 15.7187 13.4193 14.3339L18.3599 19.2746C18.4303 19.3449 18.5257 19.3845 18.6252 19.3845C18.7247 19.3845 18.8202 19.3449 18.8905 19.2746C18.9609 19.2042 19.0004 19.1088 19.0004 19.0093C19.0004 18.9097 18.9609 18.8143 18.8905 18.7439ZM1.00022 8.50926C1.00022 7.10007 1.4181 5.72252 2.201 4.55082C2.98391 3.37912 4.09668 2.46589 5.39861 1.92662C6.70053 1.38734 8.13313 1.24624 9.51524 1.52116C10.8974 1.79608 12.1669 2.47467 13.1634 3.47112C14.1598 4.46757 14.8384 5.73712 15.1133 7.11924C15.3882 8.50135 15.2471 9.93395 14.7079 11.2359C14.1686 12.5378 13.2554 13.6506 12.0837 14.4335C10.912 15.2164 9.53442 15.6343 8.12523 15.6343C6.23617 15.6323 4.42505 14.881 3.08928 13.5452C1.75352 12.2094 1.00221 10.3983 1.00022 8.50926Z" fill="#897151" />
                    </svg>
                </div>

                <div className='w-full flex flex-col gap-5'>
                    {
                        eventSearch !== "" &&
                        events
                            ?.filter((e) => e?.location?.toLowerCase().includes(eventSearch.toLowerCase()))
                            ?.filter((a) => !user?.events?.includes(a.id))
                            ?.map((event, index) => (
                                <div key={index} className='grid grid-cols-3 md:grid-cols-4 w-full bg-[#070603] bg-opacity-30 h-[74px] rounded-md items-center px-5'>
                                    <div className='text-white font-normal text-sm font-generalsans '>{event.location}</div>
                                    <div className='text-white font-normal text-sm font-generalsans md:col-span-2'>{new Intl.DateTimeFormat('en-US', dateFormatOptions).format(new Date(event.date[0].startDate))}</div>
                                    <div className="flex gap-2 justify-end"><VoxBorderButton label={'Assign'} onClick={async () => {
                                        dispatch(setLoading(true))
                                        try {
                                            const formData = new FormData();
                                            formData.append("events", JSON.stringify(user?.events ? [...user?.events, event.id] : [event.id]))
                                            formData.append('_method', 'put')

                                            const { data } = await axios.postForm(`users/${id}`, formData)
                                            setUser(data)
                                            dispatch(setLoading(false))
                                        } catch (error) {
                                            dispatch(setLoading(false))
                                        }
                                    }} /></div>
                                </div>
                            ))
                    }
                </div>

                <div className='bg-[#070603] bg-opacity-30 w-full rounded-lg pt-5 flex flex-col gap-5 acts overflow-hidden'>
                    <div className='w-full text-xl font-normal text-white px-5 font-quichesans'>Events</div>
                    <DataTable value={events?.filter((a) => user?.events?.includes(a.id))} tableStyle={{ minWidth: '50rem' }}>
                        <Column header="Event" body={(row) => (
                            <div className='flex gap-[10px] items-center'>
                                <div className='text-white text-sm font-generalsans'>{row?.location}</div>
                            </div>
                        )}></Column>
                        <Column header="Date" body={(row) => (
                            <div className='flex gap-[10px] items-center'>
                                <div className='text-white text-sm font-generalsans'>{new Intl.DateTimeFormat('en-US', dateFormatOptions).format(new Date(row.date[0].startDate))}</div>
                            </div>
                        )}></Column>
                        <Column header="ID" body={(row) => (
                            <div className='flex gap-[10px] items-center'>
                                <div className='text-white text-sm font-generalsans'>{`${row.id}`.padStart(5, '0')}</div>
                            </div>
                        )}></Column>
                        <Column header="" body={(row) => (<div className="flex gap-2 justify-end"><VoxBorderButton label={'View'} onClick={() => navigate(`/users/${id}/events/${row.id}`)} /></div>)} ></Column>
                    </DataTable>
                </div>

            </div>

            <div className='flex items-center gap-5'>
                <VoxBorderButton label={'Delete User'} className='!border-[#C51102] !text-[#C51102] text-sm' onClick={() => setModalOpen(true)} />
                <div className='text-[#C51102] text-sm'>Please Note: This action cannot be undone.</div>
            </div>

            <VoxDeleteModal open={modalOpen} setOpen={setModalOpen} btnText={'Delete User'} title={'Delete User?'} content={'Are you sure? This cannot be undone.'} onConfirm={handleConfirmDelete} />
        </div>
    )
}


export function calculateAge(birthday) {
    if (!birthday) return '';
    // Parse the birthday into a Date object
    const birthDate = new Date(birthday);

    // Get the current date
    const today = new Date();

    // Calculate the difference in years
    let age = today.getFullYear() - birthDate.getFullYear();

    // Format the birthday as DD/MM/YYYY
    const day = String(birthDate.getDate()).padStart(2, '0');
    const month = String(birthDate.getMonth() + 1).padStart(2, '0'); // Months are 0-based in JavaScript
    const year = birthDate.getFullYear();
    const formattedBirthday = `${day}/${month}/${year}`;

    // Return the formatted string
    return `${age} ${formattedBirthday}`;
}