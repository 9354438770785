import { InputText } from 'primereact/inputtext';

const VoxInput = ({ placeholder, value, onChange, classsName = '', type = 'text', name = '', label = '', labelClassName = '', error = '', readonly = false }) => {
    return (
        <div className='flex flex-col gap-[6px]'>
            {
                label && 
                <div className={`text-white text-xs font-medium ${ labelClassName }`}> {label} </div>
            }
            <InputText 
                className={`shadow-none outline-none border border-[#C1B7A8] bg-transparent rounded py-[15px] px-5 text-sm leading-5 text-white font-normal ${ classsName }`}
                placeholder={ placeholder } 
                value={value}
                onChange={onChange}
                type={ type }
                name={ name }
                readOnly={readonly}
            />
            {
                error && 
                <div className='text-[#C51102] text-sm'>{error}</div>
            }
        </div>
    )
}

export default VoxInput;