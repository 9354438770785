import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { useDispatch } from "react-redux";

import VoxBorderButton from "../../components/VoxBorderButton";
import VoxInput from "../../components/VoxInput";
import VoxButton from "../../components/VoxButton";
import VoxSuccess from "../../components/VoxSuccess";
import VoxDeleteModal from "../../components/VoxDeleteModal";
import VoxImagePicker from "../../components/VoxImagePicker";

import { setLoading } from "../../store/featrues/auth/authSlice";


export default function EditItinerary(){

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const { id, itineraryIndex } = useParams()

    const [ event, setEvent ] = useState('')
    const [ itineraries, setItineraries ] = useState([[]])
    const [ itineraryImages, setItineraryImages ] = useState([[]])
    const [ deleteImageUrls, setDeleteImageUrls ] = useState([]);
    const [ savedSuccess, setSavedSuccess ] = useState(false)
    const [ modalOpen, setModalOpen ] = useState(false)
    const [ removeModalOpen, setRemoveModalOpen ] = useState(0)

    useEffect(() => {
        dispatch(setLoading(true))
        const fetchData = async () => {
            try {
                const { data } = await axios.get(`events/${id}`)
                setEvent( data )
                setItineraries( data?.itineraries )
                let itImages = []
                for (let ele1 of data?.itineraries) {
                    let obj = {'itinerary': []}
                    for (let ele2 of ele1['itinerary']) {
                        obj['itinerary'].push("noUpdate")
                    }
                    itImages.push(obj)
                }
                setItineraryImages(itImages)
                dispatch(setLoading(false))

            } catch (error) {
                dispatch(setLoading(false))
            }
        }

        fetchData()
    }, [])

    const handleSave = async () => {
        dispatch(setLoading(true))
        const formData = new FormData();
        itineraryImages.forEach((fileRow, rowIndex) => {
            fileRow['itinerary'].forEach((file, colIndex) => {
                formData.append(`files[${rowIndex}][${colIndex}]`, file)
            })
            formData.append(`themeFiles[${rowIndex}]`, fileRow['themeImage'])
        })
        formData.append("itineraries", JSON.stringify(itineraries))
        if( deleteImageUrls.length > 0 ) {
            formData.append("deletedUrls", JSON.stringify(deleteImageUrls))
        }
        formData.append('_method', 'put');

        try {
            await axios.postForm(`events/${id}`, formData)
            dispatch(setLoading(false))
            setSavedSuccess(true)
        } catch (error) {
            dispatch(setLoading(false))
        }
    }

    const handleUnassignAct = async () => {
        setModalOpen(false)
        dispatch(setLoading(true))
        let itis = [...itineraries]
        itis.splice(itineraryIndex, 1)
        const formData = new FormData();
        const urls = []
        itineraries[itineraryIndex]['itinerary'].forEach((iti, rowIndex) => {
            urls.push(iti.image)
        })
        formData.append("deletedUrls", JSON.stringify(urls))
        formData.append("itineraries", JSON.stringify(itis))
        formData.append('_method', 'put');
        
        try {
            const { data } = await axios.postForm(`events/${id}`, formData)
            dispatch(setLoading(false))
            navigate(`/events/${id}`)
        } catch (error) {
            dispatch(setLoading(false))
        }
    }

    const handleRemoveItem = async () => {
        const itemIndex = removeModalOpen - 1;
        setRemoveModalOpen(0)

        const newItineraries = itineraries[itineraryIndex]['itinerary'].filter((ele, index) => index!= itemIndex)
        const removedItem = itineraries[itineraryIndex]['itinerary'].filter((ele, index) => index == itemIndex)
        if( removedItem[0]?.image ) {
            setDeleteImageUrls((pre) => [...pre, removedItem[0]?.image])
        }
        setItineraries((pre) => {
            let itis = [...pre]
            itis[itineraryIndex]['itinerary'] = newItineraries
            return itis
        })
        setItineraryImages((pre) => {
            let itis = [...pre]
            itis[itineraryIndex]['itinerary'].splice(itemIndex, 1)
            return itis
        })
    }

    return (
        <div className="bg-[#18140c] flex flex-col gap-5 w-full rounded-lg pt-5 pb-10 items-center min-h-[645px]">
            <div className="w-full text-white px-5 justify-between flex items-center border-b border-b-[##5B5549] pb-5 text-xl font-quichesans font-normal">
                {`Edit Day ${ Number(itineraryIndex) + 1 } Itinerary`}

                <VoxBorderButton label={'Cancel'} onClick={() => navigate(`/events/${id}`)} />
            </div>

            {
                !savedSuccess > 0 && itineraries[itineraryIndex] && 
                <div className="max-w-[690px] w-full pt-10 gap-5 flex flex-col px-5">

                    <div className="flex flex-row gap-5">
                        <div className="flex-grow">
                            <VoxInput
                                placeholder={'THEME'}
                                classsName="shadow-none w-full"
                                value={itineraries[itineraryIndex]['theme']}
                                onChange={(e) => setItineraries((pre) => {
                                    let newIti = [...pre]
                                    newIti[itineraryIndex] = { ...newIti[itineraryIndex], theme: e.target.value }
                                    return newIti
                                })}
                            />
                        </div>
                        <div>
                            <VoxImagePicker
                                label={'add theme image'}
                                setFile={(file) => setItineraryImages((pre) => {
                                    let newIti = [...pre]
                                    newIti[itineraryIndex]['themeImage'] = file
                                    return newIti
                                })}
                                file={itineraryImages[itineraryIndex]['themeImage']}
                                url={itineraries[itineraryIndex]['themeImage']}
                            />
                        </div>
                    </div>

                    {
                        itineraries[itineraryIndex]['itinerary']?.map((i, index1) => 
                            <div className="w-full bg-[#070603] p-5 gap-5 bg-opacity-30 grid grid-cols-1 lg:grid-cols-2 rounded-[5px]" key={index1}>
                                <div className="text-[#897151] text-xs font-medium uppercase lg:col-span-2 flex flex-row justify-between items-center">
                                    {`activity item`}
                                    <VoxBorderButton label={'Remove'} className='!border-[#C51102] !text-[#C51102] text-sm' onClick={() => (setRemoveModalOpen(index1 + 1))}  />
                                    
                                </div>
                                <VoxImagePicker 
                                    label={'add feature image'}
                                    setFile={(file) => setItineraryImages((pre) => {
                                        let newIti = [...pre]
                                        newIti[itineraryIndex]['itinerary'][index1] = file
                                        return newIti
                                    })}
                                    url={itineraries[itineraryIndex]['itinerary'][index1]?.image}
                                    file={itineraryImages[itineraryIndex]['itinerary'][index1]}
                                />
                                <VoxInput 
                                    placeholder={'ACTIVITY NAME'} 
                                    classsName="w-full" 
                                    onChange={(e) => setItineraries((pre) => {
                                        let newIti = [...pre]
                                        newIti[itineraryIndex]['itinerary'][index1] = {...newIti[itineraryIndex]['itinerary'][index1], name: e.target.value}
                                        return newIti
                                    })}
                                    value={itineraries[itineraryIndex]['itinerary'][index1].name}
                                />
                                <select 
                                    className="border border-[#C1B7A8] rounded-[5px] py-[15px] px-[20px] flex justify-between text-xs text-white bg-transparent" 
                                    placeholder="DURATION"
                                    onChange={(e) => setItineraries((pre) => {
                                        let newIti = [...pre]
                                        newIti[itineraryIndex]['itinerary'][index1] = {...newIti[itineraryIndex]['itinerary'][index1], duration: e.target.value}
                                        return newIti
                                    })}
                                    value={itineraries[itineraryIndex]['itinerary'][index1].duration}
                                >
                                    <option value={''}>DURATION</option>
                                    <option>1 hour</option>
                                    <option>2 hours</option>
                                    <option>3 hours</option>
                                    <option>4 hours</option>
                                    <option>5 hours</option>
                                    <option>6 hours</option>
                                    <option>7 hours</option>
                                    <option>8 hours</option>
                                    <option>9 hours</option>
                                    <option>10 hours</option>
                                </select>
                                <input 
                                    type="time" 
                                    className="border border-[#C1B7A8] rounded-[5px] py-[15px] px-[20px] flex justify-between text-xs text-white bg-transparent" 
                                    onFocus={(e) => e.target?.showPicker()}
                                    onChange={(e) => setItineraries((pre) => {
                                        let newIti = [...pre]
                                        newIti[itineraryIndex]['itinerary'][index1] = {...newIti[itineraryIndex]['itinerary'][index1], time: e.target.value}
                                        return newIti
                                    })}
                                    value={itineraries[itineraryIndex]['itinerary'][index1].time}
                                />
                            </div>
                        )
                    }

                    <VoxBorderButton label={'Add Item'} iconType={'plus'} className="w-max" onClick={() => {
                        setItineraries((pre) => {
                            const newIti = [...pre]
                            newIti[itineraryIndex]['itinerary'].push({
                                name: '',
                                duration: '',
                                time: ''
                            })
                            return newIti
                        })
                        setItineraryImages((pre) => {
                            const newIti = [...pre]
                            newIti[itineraryIndex]['itinerary'].push(null)
                            return newIti
                        })
                    }} />

                    <VoxButton 
                        className="w-max mt-5" 
                        label={'Update Itinerary'} 
                        onClick={handleSave}
                    />

                    <div className='flex items-center gap-5 flex-wrap mt-5'>
                        <VoxBorderButton label={'Delete Day'} className='!border-[#C51102] !text-[#C51102] text-sm' onClick={() => setModalOpen(true)}  />
                        <div className='text-[#C51102] text-sm'>Please Note: This action cannot be undone.</div>
                    </div>
                </div>
            }

            {
                savedSuccess && 
                <VoxSuccess 
                    content={'Itinerary successfully updated.'}
                    btnLabel={'Back to event'}
                    to={`/events/${id}`}
                />
            }

            <VoxDeleteModal open={modalOpen} setOpen={setModalOpen}  onConfirm={handleUnassignAct} title={'Delete Day?'} btnText={'Delete Day'} />
            <VoxDeleteModal open={!!removeModalOpen} setOpen={() => setRemoveModalOpen(0)}  onConfirm={handleRemoveItem} title={'Delete Item?'} btnText={'Delete Item'} />
        </div>
    )
}