


export default function BoxBorderButton({label, iconType, className = '', onClick, type = "normal"}) {

    var borderColor = "border-[#897151]"
    var textColor = "text-white"
    if( type === "danger" ) {
        borderColor = "border-[#C51102]"
        textColor = "text-[#C51102]"
    }

    return (
        <button className={`py-[10px] px-5 rounded-[100px] ${borderColor} border text-base font-normal ${textColor} gap-3 flex items-center font-generalsans ${className}`} onClick={onClick}>
            {
                label
            }
            {
                iconType === "plus" && 
                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M5.99944 1V11" stroke="white" strokeLinecap="square"/>
                    <path d="M11 5.99999H1" stroke="white" strokeLinecap="square"/>
                </svg>
            }
            {
                iconType === "minus" && 
                <svg width="12" height="2" viewBox="0 0 12 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M11 1.00042H1" stroke="#C51102" strokeLinecap="square"/>
                </svg>
            }
        </button>
    )
}