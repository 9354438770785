import { useEffect, useState } from 'react'
import axios from 'axios'
import { useParams, useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import VoxBorderButton from '../../components/VoxBorderButton'
import VoxDeleteModal from '../../components/VoxDeleteModal';
import { setLoading } from '../../store/featrues/auth/authSlice'
import { setTo, setTitle } from '../../store/featrues/data/dataSlice'


export default function SingleEvent() {

    const [event, setEvent] = useState(null)
    const { id } = useParams()
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [acts, setActs] = useState([])
    const [itineraryIndex, setItineraryIndex] = useState(0)
    const [modalOpen, setModalOpen] = useState(false)

    useEffect(() => {
        dispatch(setTitle("Events"))
        dispatch(setTo(""))
        dispatch(setLoading(true))
        const fetchEvent = async (id) => {
            try {
                const { data } = await axios.get(`events/${id}`);
                setEvent(data)
                const response = await axios.get('acts')
                setActs(response?.data)
                dispatch(setLoading(false))
            } catch (error) {
                dispatch(setLoading(false))
            }
        }

        fetchEvent(id)
    }, [])

    const handleConfirmDelete = async () => {
        try {
            await axios.delete(`events/${id}`)
            setModalOpen(false)
            navigate('/events')
        } catch (error) {
            console.log(error)
        }
    }



    return (
        <div className="w-full flex flex-col gap-8">
            <div className="bg-[#18140c] flex flex-col gap-10 w-full rounded-lg items-center px-9 py-8">
                <div className="flex justify-between w-full items-center">
                    <div className="text-white font-quichesans font-normal text-xl">Event Details</div>
                    <VoxBorderButton label={'Edit'} onClick={() => navigate(`/events/${id}/edit`)} />
                </div>

                <div className='grid grid-cols-1 md:grid-cols-4 w-full gap-5'>
                    <div className='flex flex-col gap-[10px]'>
                        <div className='uppercase text-xs text-[#897151]'>Featured Image</div>
                        <img src={`${process.env.REACT_APP_STORAGE_URL}${event?.image}`} className='w-[50px] h-[50px] rounded-full' alt='' />
                    </div>

                    <div className='flex flex-col gap-[10px]'>
                        <div className='uppercase text-xs text-[#897151]'>date</div>
                        <div className='text-white text-base font-normal font-generalsans leading-6'>{`${('0' + (new Date(event?.date[0]?.startDate).getMonth() + 1)).slice(-2)} - ${('0' + (new Date(event?.date[0]?.endDate).getMonth() + 1)).slice(-2)} / ${new Date(event?.date[0]?.endDate).getUTCFullYear()}`}</div>
                    </div>

                    <div className='flex flex-col gap-[10px]'>
                        <div className='uppercase text-xs text-[#897151]'>time</div>
                        <div className='text-white text-base font-normal font-generalsans leading-6'>{event?.time}</div>
                    </div>

                    <div className='flex flex-col gap-[10px]'>
                        <div className='uppercase text-xs text-[#897151]'>location</div>
                        <div className='text-white text-base font-normal font-generalsans leading-6'>{event?.location}</div>
                    </div>
                </div>
                <div className='flex flex-col gap-[10px] w-full'>
                    <div className='uppercase text-xs text-[#897151]'>event details</div>
                    <div className='text-white text-base font-normal text-wrap whitespace-pre font-generalsans leading-6'>{event?.details}</div>
                </div>
            </div>

            <div className="bg-[#18140c] flex flex-col gap-10 w-full rounded-lg items-center px-9 py-8">
                <div className="flex justify-between w-full items-center">
                    <div className="text-xl text-white font-normal font-quichesans">Acts</div>
                </div>

                <div className="w-full h-[58px] border-[#5B5549] border rounded-md flex items-center px-5">
                    <input
                        className="bg-transparent w-full text-sm font-normal text-white outline-none font-generalsans"
                        placeholder="Assign Act"
                    // value={actSearch}
                    // onChange={(e) => setActSearch(e.target.value)}
                    />
                    <svg width="19" height="20" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M18.8905 18.7439L13.9499 13.8033C15.3346 12.2801 16.0687 10.2757 15.9952 8.2184C15.9217 6.16114 15.0465 4.21421 13.5566 2.79364C12.0668 1.37308 10.0804 0.591538 8.02199 0.616038C5.96356 0.640538 3.99635 1.46913 2.54073 2.92476C1.0851 4.38039 0.256504 6.3476 0.232004 8.40602C0.207504 10.4644 0.989042 12.4508 2.40961 13.9407C3.83018 15.4305 5.77711 16.3057 7.83437 16.3792C9.89163 16.4527 11.896 15.7187 13.4193 14.3339L18.3599 19.2746C18.4303 19.3449 18.5257 19.3845 18.6252 19.3845C18.7247 19.3845 18.8202 19.3449 18.8905 19.2746C18.9609 19.2042 19.0004 19.1088 19.0004 19.0093C19.0004 18.9097 18.9609 18.8143 18.8905 18.7439ZM1.00022 8.50926C1.00022 7.10007 1.4181 5.72252 2.201 4.55082C2.98391 3.37912 4.09668 2.46589 5.39861 1.92662C6.70053 1.38734 8.13313 1.24624 9.51524 1.52116C10.8974 1.79608 12.1669 2.47467 13.1634 3.47112C14.1598 4.46757 14.8384 5.73712 15.1133 7.11924C15.3882 8.50135 15.2471 9.93395 14.7079 11.2359C14.1686 12.5378 13.2554 13.6506 12.0837 14.4335C10.912 15.2164 9.53442 15.6343 8.12523 15.6343C6.23617 15.6323 4.42505 14.881 3.08928 13.5452C1.75352 12.2094 1.00221 10.3983 1.00022 8.50926Z" fill="#897151" />
                    </svg>
                </div>

                <div className='bg-[#070603] bg-opacity-30 w-full rounded-lg pt-5 flex flex-col gap-5 acts overflow-hidden'>
                    <div className='w-full text-xl font-normal text-white px-5 font-quichesans'>Acts</div>
                    <DataTable value={acts?.filter((a) => event?.acts?.includes(a.id))} tableStyle={{ minWidth: '50rem' }}>
                        <Column header="Act" body={(row) => (
                            <div className='flex gap-[10px] items-center'>
                                <img src={`${process.env.REACT_APP_STORAGE_URL}${row.image}`} className='w-[50px] h-[50px] rounded-full' alt='' />
                                <div className='text-white text-sm'>{row?.name}</div>
                            </div>
                        )}></Column>
                        <Column header="" body={(row) => (<div className="flex gap-2 justify-end"><VoxBorderButton label={'Edit'} onClick={() => navigate(`/events/${id}/edit-act/${row.id}`)} /></div>)} ></Column>
                    </DataTable>
                </div>

            </div>

            <div className="bg-[#18140c] flex flex-col gap-5 w-full rounded-lg py-5">
                <div className="w-full text-white px-5 text-xl flex justify-between items-center font-normal font-quichesans">
                    {`Itinerary`}

                    <div className='flex items-center gap-5'>
                        <select
                            className="border border-[#5B5549] py-[10px] px-[20px] flex justify-between text-base text-white bg-transparent rounded-[100px] outline-none"
                            placeholder="DURATION"
                            onChange={(e) => setItineraryIndex(e.target.value)}
                            value={itineraryIndex}
                        >
                            {
                                event?.itineraries.map((itinerary, index) => (
                                    <option key={index} value={index}>{`Day ${index + 1}`}</option>
                                ))
                            }
                        </select>

                        <VoxBorderButton label={'Add New Day'} onClick={ async () => {
                            dispatch(setLoading(true))
                            try {
                                const itineraries = [...event.itineraries, { theme: '', itinerary: [], themeImage: null }]
                                const formData = new FormData()
                                formData.append("itineraries", JSON.stringify(itineraries))
                                formData.append('_method', 'put')
                                const { data } = await axios.postForm(`events/${id}`, formData)
                                setEvent(data)
                                dispatch(setLoading(false))
                            } catch (error) {
                                dispatch(setLoading(false))
                            }
                        }} />
                        <VoxBorderButton label={'Edit'} onClick={() => event?.itineraries.length && navigate(`/events/${id}/edit-itinerary/${itineraryIndex}`)} />
                    </div>
                </div>

                <DataTable value={event?.itineraries[itineraryIndex]['itinerary'] ?? []} tableStyle={{ minWidth: '50rem' }}>
                    <Column field="name" header="Activity"></Column>
                    <Column field='time' header="Time"></Column>
                    <Column field='duration' header="Duration"></Column>
                </DataTable>
            </div>

            <div className='flex items-center gap-5'>
                <VoxBorderButton label={'Delete Event'} type='danger' className='text-sm' onClick={() => setModalOpen(true)} />
                <div className='text-[#C51102] text-sm'>Please Note: This action cannot be undone.</div>
            </div>

            <VoxDeleteModal open={modalOpen} setOpen={setModalOpen} btnText={'Delete Event'} title={'Delete Event?'} content={'Are you sure? This cannot be undone.'} onConfirm={handleConfirmDelete} />
        </div>
    )
}