import { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import axios from 'axios'

import { setLoading } from '../../store/featrues/auth/authSlice'
import { setTo, setTitle } from '../../store/featrues/data/dataSlice'

import VoxBorderButton from '../../components/VoxBorderButton'
import VoxButton from '../../components/VoxButton'
import VoxInput from '../../components/VoxInput'
import VoxSuccess from '../../components/VoxSuccess'


export default function EditUserPreferences() {

    const navigate = useNavigate()
    const { id } = useParams()
    const dispatch = useDispatch()

    const [user, setUser] = useState(null)

    const [savedSuccess, setSavedSuccess] = useState(false)

    useEffect(() => {
        dispatch(setTo(""))
        dispatch(setTitle("Users"))
        dispatch(setLoading(true))

        const fetchEvent = async (id) => {
            try {
                const { data } = await axios.get(`users/${id}`);
                setUser(data)
                dispatch(setLoading(false))
            } catch (error) {
                dispatch(setLoading(false))
            }
        }

        fetchEvent(id)
    }, [dispatch, id])


    const handleSaveChanges = async () => {
        dispatch(setLoading(true))
        const formData = new FormData();
        formData.append("allergies", user?.allergies)
        formData.append("intolerances", user?.intolerances)
        formData.append("other_allergies_intolerances", user?.other_allergies_intolerances)
        formData.append("religious_food_restrictions", user?.religious_food_restrictions)
        formData.append("favourite_foods", user?.favourite_foods)
        formData.append("food_dislikes", user?.food_dislikes)
        formData.append("favourite_soft_drinks", user?.favourite_soft_drinks)
        formData.append("favourite_alcoholic_drink_cocktail", user?.favourite_alcoholic_drink_cocktail)
        formData.append("specific_food_beverage_requests", user?.specific_food_beverage_requests)
        formData.append("specific_room_requests", user?.specific_room_requests)
        formData.append("additional_information", user?.additional_information)
        formData.append('_method', 'put')

        try {
            await axios.postForm(`users/${id}`, formData)
            setSavedSuccess(true)
            dispatch(setLoading(false))
        } catch (error) {
            console.log(error)
            dispatch(setLoading(false))
        }
    }


    return (
        <div className="bg-[#18140c] flex flex-col gap-5 w-full rounded-lg pt-5 pb-10 items-center min-h-[645px]">
            <div className="w-full text-white px-5 justify-between flex items-center border-b border-b-[##5B5549] pb-5 font-normal text-2xl font-quichesans">
                {`Edit Preferences`}

                <VoxBorderButton label={'Cancel'} onClick={() => navigate(`/users/${id}`)} />
            </div>
            {
                !savedSuccess &&
                <div className="max-w-[690px] w-full pt-10 gap-10 flex flex-col px-5">
                    <div className='flex flex-col gap-10 w-full'>
                        <div className="w-full grid grid-cols-1 md:grid-cols-2 mt-8 gap-5">
                            <div className='md:col-span-2 text-white font-quichesans font-normal text-xl'>Allergies & Restrictions</div>
                            <VoxInput placeholder={''} classsName="shadow-none w-full" value={user?.allergies} onChange={(e) => setUser((pre) => ({ ...pre, allergies: e.target.value }))} label={"ALLERGIES"} />
                            <VoxInput placeholder={''} classsName="shadow-none w-full" value={user?.intolerances} onChange={(e) => setUser((pre) => ({ ...pre, intolerances: e.target.value }))} label={("Intolerances").toUpperCase()} />
                            <VoxInput placeholder={''} classsName="shadow-none w-full" value={user?.other_allergies_intolerances} onChange={(e) => setUser((pre) => ({ ...pre, other_allergies_intolerances: e.target.value }))} label={("other allergies/intolerances").toUpperCase()} />
                            <VoxInput placeholder={''} classsName="shadow-none w-full" value={user?.religious_food_restrictions} onChange={(e) => setUser((pre) => ({ ...pre, religious_food_restrictions: e.target.value }))} label={("Religious Food Restrictions").toUpperCase()} />
                        </div>

                        <div className="w-full grid grid-cols-1 md:grid-cols-2 mt-8 gap-5">
                            <div className='md:col-span-2 text-white font-quichesans font-normal text-xl'>Food & Drink</div>
                            <VoxInput placeholder={''} classsName="shadow-none w-full" value={user?.favourite_foods} onChange={(e) => setUser((pre) => ({ ...pre, favourite_foods: e.target.value }))} label={("Favourite Foods").toUpperCase()} />
                            <VoxInput placeholder={''} classsName="shadow-none w-full" value={user?.food_dislikes} onChange={(e) => setUser((pre) => ({ ...pre, food_dislikes: e.target.value }))} label={("Food Dislikes").toUpperCase()} />
                            <VoxInput placeholder={''} classsName="shadow-none w-full" value={user?.favourite_soft_drinks} onChange={(e) => setUser((pre) => ({ ...pre, favourite_soft_drinks: e.target.value }))} label={("Favourite Soft Drinks").toUpperCase()} />
                            <VoxInput placeholder={''} classsName="shadow-none w-full" value={user?.favourite_alcoholic_drink_cocktail} onChange={(e) => setUser((pre) => ({ ...pre, favourite_alcoholic_drink_cocktail: e.target.value }))} label={("Favourite Alcoholic Drink/cocktail").toUpperCase()} />
                        </div>

                        <div className="w-full grid grid-cols-1 md:grid-cols-2 mt-8 gap-5">
                            <div className='md:col-span-2 text-white font-quichesans font-normal text-xl'>Any other information we can be aware of to ensure your experience is seamless?</div>
                            <VoxInput placeholder={''} classsName="shadow-none w-full" value={user?.specific_food_beverage_requests} onChange={(e) => setUser((pre) => ({ ...pre, specific_food_beverage_requests: e.target.value }))} label={("Specific Food/Beverage Requests").toUpperCase()} />
                            <VoxInput placeholder={''} classsName="shadow-none w-full" value={user?.specific_room_requests} onChange={(e) => setUser((pre) => ({ ...pre, specific_room_requests: e.target.value }))} label={("Specific Room Requests").toUpperCase()} />
                            <div className='md:col-span-2'>
                                <VoxInput placeholder={''} classsName="shadow-none w-full" value={user?.additional_information} onChange={(e) => setUser((pre) => ({ ...pre, additional_information: e.target.value }))} label={("Additional Information").toUpperCase()} />
                            </div>
                        </div>
                    </div>

                    <VoxButton label={'Save Changes'} onClick={handleSaveChanges} className='w-max text-sm' />
                </div>
            }
            {
                savedSuccess &&
                <VoxSuccess content={'User preferences successfully updated.'} to={`/users/${id}`} btnLabel={'Back to users'} />
            }
        </div>
    )
}