import { useEffect, useState, useRef } from "react";
import { useDispatch } from "react-redux";
import axios from "axios";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { useNavigate } from "react-router-dom";
import { Dropdown } from 'primereact/dropdown';

import { setTitle, setTo } from "../../store/featrues/data/dataSlice";
import { setLoading } from "../../store/featrues/auth/authSlice";

import VoxBorderButton from "../../components/VoxBorderButton";

export default function Explore() {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [ events, setEvents ] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [categories, setCategories] = useState([])
    const dropdownRef = useRef(null)


    useEffect(() => {
        dispatch(setTitle("Explore"))
        dispatch(setTo('/explore/add-new'))
        dispatch(setLoading(true))

        const fetchEvents = async(selectedCategory) => {
            try {
                const { data } = await axios.get('explore', {
                    params: {
                        category: selectedCategory?.name
                    }
                })
                setEvents(data?.explores)
                setCategories(data?.categories)
                dispatch(setLoading(false))
            } catch (error) {
                dispatch(setLoading(false))
            }
        }

        fetchEvents(selectedCategory)
    }, [selectedCategory, dispatch])

    const handleItemClick = (name) => {
        if( selectedCategory?.name === name ) {
            setSelectedCategory(null)
        } else {
            setSelectedCategory({name})
        }
    };

    const itemTemplate = (option) => {
        return (
            <div onClick={() => handleItemClick(option.name)} className="p-dropdown-custom-label">
                {option.name}
            </div>
        );
    };
    
    return (
        <div className="bg-[#18140c] flex flex-col gap-5 w-full rounded-lg py-5">
            <div className="w-full text-white px-5 font-quichesans font-normal text-xl flex justify-between items-center">
                {`Explore`}

                <Dropdown 
                    value={selectedCategory} 
                    options={categories} 
                    optionLabel="name" 
                    placeholder="Filter by Category" 
                    className="bg-transparent border rounded-[100px] border-[#5B5549] !shadow-none [&>.p-dropdown-label]:bg-transparent [&>.p-dropdown-label]:text-white [&>.p-dropdown-label]:text-base [&>.p-dropdown-label]:font-normal [&>.p-dropdown-label]:pl-5" 
                    ref={dropdownRef}
                    itemTemplate={itemTemplate}
                />
            </div>

            <DataTable value={ events } tableStyle={{ minWidth: '50rem' }}>
                <Column header="Title" field="title"></Column>
                <Column header="Date" body={ (row) => new Date(row.created_at).toLocaleDateString('en-GB').replace(/\d{4}$/, year => year.slice(-2)) }></Column>
                <Column header="Category" field="category"></Column>
                <Column header="" body={ (row) => (<div className="flex gap-2 justify-end"><VoxBorderButton label={'Edit'} onClick={() => navigate(`/explore/${row.id}/edit`)} /></div>) } ></Column>
            </DataTable>
        </div>
    )
}