

export default function VoxError({label}) {

    return (
        <div className="h-[50px] rounded-[5px] w-full bg-[#C51102] flex flex-row items-center gap-[10px] text-white px-[10px] font-normal text-sm py-[10px]">
            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_10943_10799)">
                    <circle cx="16" cy="16" r="16" fill="white"/>
                    <mask id="mask0_10943_10799" maskUnits="userSpaceOnUse" x="10" y="10" width="12" height="12">
                        <path fillRule="evenodd" clipRule="evenodd" d="M16.0003 10.667C13.0563 10.667 10.667 13.0563 10.667 16.0003C10.667 18.9443 13.0563 21.3337 16.0003 21.3337C18.9443 21.3337 21.3337 18.9443 21.3337 16.0003C21.3337 13.0563 18.9443 10.667 16.0003 10.667V10.667ZM16.5337 18.667H15.467V17.6003H16.5337V18.667V18.667ZM16.5337 16.5337H15.467V13.3337H16.5337V16.5337V16.5337Z" fill="white"/>
                    </mask>
                    <g mask="url(#mask0_10943_10799)">
                        <rect x="2.66602" y="2.66602" width="26.6667" height="26.6667" fill="#C51102"/>
                        <mask id="mask1_10943_10799" maskUnits="userSpaceOnUse" x="2" y="2" width="28" height="28">
                            <rect x="2.66602" y="2.66602" width="26.6667" height="26.6667" fill="white"/>
                        </mask>
                        <g mask="url(#mask1_10943_10799)">
                        </g>
                    </g>
                </g>
                <defs>
                    <clipPath id="clip0_10943_10799">
                        <rect width="32" height="32" fill="white"/>
                    </clipPath>
                </defs>
            </svg>

            {
                label
            }
        </div>
    )
}