import { Card } from 'primereact/card';
import { useNavigate } from 'react-router-dom';

import VoxButton from '../../components/VoxButton';

import logo from "../../assets/svg/logo-l.svg";
import logoS from "../../assets/svg/logo-s.svg";

export default function SuccessSentEmail() {

    const navigate = useNavigate()

    return (
        <div className='min-h-[100vh] w-full flex justify-center items-center bg-[#070603] py-10'>
            <Card className='bg-[#18140c] lg:px-[120px] lg:py-[166px] sm:px-[60px] sm:py-[30px] px-[20px] py-[40px] rounded-lg '>
                <div className='flex flex-col gap-[60px] lg:gap-[275px] lg:flex-row sm:px-[126px] sm:pb-[80px] sm:lg:px-0 lg:pb-0'>
                    <div className='flex flex-col gap-8 sm:w-[335px] w-[295px] justify-center'>
                        <div className='flex flex-col gap-5'>
                            <div className='header4 text-[25px] leading-[31px]'>Success!</div>
                            <div className='content4 text-base font-generalsans'>Check your inbox. We have sent you an email with instructions on how to reset your password.</div>
                        </div>

                        <div className='flex flex-col gap-5'>
                            <VoxButton label='Back to Log In' onClick={() => navigate("/login")} />
                        </div>
                    </div>

                    <img src={logo} alt="logo-image" className='hidden sm:block' />
                    <img src={logoS} alt="logo-image" className='sm:hidden block' />
                </div>
            </Card>
        </div>
    )
}