import { useState, useEffect, useRef } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import axios from 'axios'
import { Calendar } from 'react-date-range';

import { setLoading } from '../../store/featrues/auth/authSlice'
import { setTo, setTitle } from '../../store/featrues/data/dataSlice'

import VoxBorderButton from '../../components/VoxBorderButton'
import VoxButton from '../../components/VoxButton'
import VoxImagePicker from '../../components/VoxImagePicker'
import VoxInput from '../../components/VoxInput'
import VoxSuccess from '../../components/VoxSuccess'

import { calculateAge } from './single-user';


export default function EditUserDetail() {

    const navigate = useNavigate()
    const { id } = useParams()
    const dispatch = useDispatch()

    const dateRef = useRef(null)

    const [user, setUser] = useState(null)
    const [profilePicture, setProfilePicture] = useState(null)

    const [savedSuccess, setSavedSuccess] = useState(false)
    const [showDateRangePicker, setShowDateRangePicker] = useState(false)
    const [ageBirthday, setAgeBirthday] = useState('')

    useEffect(() => {
        dispatch(setTo(""))
        dispatch(setTitle("Users"))
        dispatch(setLoading(true))

        const fetchEvent = async (id) => {
            try {
                const { data } = await axios.get(`users/${id}`);
                setUser(data)
                dispatch(setLoading(false))
            } catch (error) {
                dispatch(setLoading(false))
            }
        }

        fetchEvent(id)
    }, [dispatch, id])


    const handleClickOutside = (event) => {
        if (dateRef.current && !dateRef.current.contains(event.target)) {
            setShowDateRangePicker(false);
        }
    };

    useEffect(() => {
        if (showDateRangePicker) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [showDateRangePicker]);

    useEffect(() => {
        setAgeBirthday(calculateAge(user?.birthday))
    }, [user])


    const handleSaveChanges = async () => {
        dispatch(setLoading(true))
        const formData = new FormData()
        if (profilePicture) {
            formData.append("file", profilePicture)
        }
        formData.append("name", user?.name)
        formData.append("lname", user?.lname)
        formData.append("birthday", user?.birthday)
        formData.append("phone", user?.phone)
        formData.append("email", user?.email)
        formData.append("role", user?.role)
        formData.append('_method', 'put')

        try {
            await axios.postForm(`users/${id}`, formData)
            setSavedSuccess(true)
            dispatch(setLoading(false))
        } catch (error) {
            console.log(error)
            dispatch(setLoading(false))
        }
    }

    const handleSelect = async (date) => {
        setShowDateRangePicker(false)
        setUser((pre) => ({ ...pre, birthday: date }))
    }


    return (
        <div className="bg-[#18140c] flex flex-col gap-5 w-full rounded-lg pt-5 pb-10 items-center min-h-[645px]">
            <div className="w-full text-white px-5 justify-between flex items-center border-b border-b-[##5B5549] pb-5 font-normal text-xl font-quichesans">
                {`Edit User Details`}

                <VoxBorderButton label={'Cancel'} onClick={() => navigate(`/users/${id}`)} />
            </div>
            {
                !savedSuccess &&
                <div className="max-w-[690px] w-full pt-10 gap-10 flex flex-col px-5">
                    <div className="w-full flex flex-col gap-5">
                        <VoxImagePicker
                            label={'Edit Profile picture'}
                            setFile={setProfilePicture}
                            url={user?.image}
                            file={profilePicture}
                            isEdit
                        />

                        <div className="w-full grid grid-cols-1 md:grid-cols-2 mt-8 gap-5">
                            <VoxInput placeholder={''} classsName="shadow-none w-full" value={user?.name} onChange={(e) => setUser((pre) => ({ ...pre, name: e.target.value }))} label={"FIRST NAME"} />
                            <VoxInput placeholder={''} classsName="shadow-none w-full" value={user?.lname} onChange={(e) => setUser((pre) => ({ ...pre, lname: e.target.value }))} label={"LAST NAME"} />
                            <div className='w-full relative flex flex-col gap-[6px]'>
                                <div className='text-white text-xs font-medium uppercase'>Age/Birthday</div>
                                <button className="border border-[#757471] rounded-[5px] py-[15px] px-[20px] flex justify-between text-sm text-white w-full h-[50px]" onClick={() => setShowDateRangePicker((pre) => !pre)}>
                                    {
                                        ageBirthday
                                    }
                                </button>
                                <div ref={dateRef}>
                                    <Calendar
                                        date={new Date(user?.birthday || new Date())}
                                        onChange={handleSelect}
                                        className={`absolute top-20 ${showDateRangePicker ? 'block' : '!hidden'}`}
                                    />
                                </div>
                            </div>
                            <VoxInput placeholder={''} classsName="shadow-none w-full" value={user?.phone} onChange={(e) => setUser((pre) => ({ ...pre, phone: e.target.value }))} label={"PHONE"} />
                            <VoxInput placeholder={''} classsName="shadow-none w-full" value={user?.email} onChange={(e) => setUser((pre) => ({ ...pre, email: e.target.value }))} label={"EMAIL ADDRESS"} />
                            <div className='flex flex-col gap-[6px]'>
                                <div className={`text-white text-xs font-medium`}> ROLE </div>
                                <select
                                    className="border border-[#C1B7A8] rounded-[5px] py-[15px] px-[20px] flex justify-between text-sm text-white bg-transparent w-full"
                                    placeholder="ROLE"
                                    onChange={(e) => setUser((pre) => ({ ...pre, role: e.target.value }))}
                                    value={user?.role}
                                >
                                    <option>Guest</option>
                                    <option>Admin</option>
                                </select>
                            </div>
                        </div>
                    </div>

                    <VoxButton label={'Save Changes'} onClick={handleSaveChanges} className='w-max text-sm' />
                </div>
            }
            {
                savedSuccess &&
                <VoxSuccess content={'User details successfully updated.'} to={`/users/${id}`} btnLabel={'Back to users'} />
            }
        </div>
    )
}