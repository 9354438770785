import { useState } from 'react';
import axios from 'axios';
import { Card } from 'primereact/card';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setAuthToken } from '../../store/featrues/auth/authSlice';

import VoxInput from '../../components/VoxInput';
import VoxButton from '../../components/VoxButton';
import VoxA from '../../components/VoxA';

import logo from "../../assets/svg/logo-l.svg";
import logoS from "../../assets/svg/logo-s.svg";

export default function Login() {

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('')
    const [error, setError] = useState('')
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const handleLogin = async () => {
        setError("")

        try {
            const response = await axios.post('auth/login', {
                email,
                password
            })

            localStorage.setItem('authToken', response.data.access_token)
            dispatch(setAuthToken(response.data.access_token))
            navigate("/invites")
        } catch (error) {
            console.log(error?.response?.data?.message)
            setError(error?.response?.data?.message)
        }
    }

    return (
        <div className='min-h-[100vh] w-full flex justify-center items-center bg-[#070603] py-10'>
            <Card className='bg-[#18140c] lg:px-[120px] lg:py-[166px] sm:px-[60px] sm:py-[30px] px-[20px] py-[40px] rounded-lg '>
                <div className='flex flex-col gap-[60px] lg:gap-[275px] lg:flex-row sm:px-[126px] sm:pb-[80px] sm:lg:px-0 lg:pb-0'>
                    <div className='flex flex-col gap-8 sm:w-[335px] w-[295px]'>
                        <div className='flex flex-col gap-5'>
                            <div className='header4 text-[25px] leading-[31px] font-quichesans tracking-wide'>Welcome to Vox of Amici</div>
                            <div className='content4 text-base font-generalsans'>Log in by filling out the details below.</div>
                        </div>
                        <div className='flex flex-col gap-5'>
                            <VoxInput
                                placeholder="ADMIN EMAIL"
                                classsName='w-full'
                                name="email"
                                onChange={(e) => setEmail(e.target.value)}
                                value={email}
                            />
                            <VoxInput
                                placeholder="PASSWORD"
                                classsName='w-full'
                                type='password'
                                name="password"
                                onChange={(e) => setPassword(e.target.value)}
                                value={password}
                            />
                            {
                                error &&
                                <div className='text-red-500 text-base'>{error}</div>
                            }
                        </div>

                        <div className='flex flex-col gap-5'>
                            <VoxButton
                                label='Login'
                                onClick={() => handleLogin()}
                            />
                            <VoxA label='Forgot Password?' className="uppercase" to={'/reset-password'} />
                        </div>
                    </div>

                    <img src={logo} alt="logo-image" className='hidden sm:block' />
                    <img src={logoS} alt="logo-image" className='sm:hidden block' />
                </div>
            </Card>
        </div>
    )
}