import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import axios from "axios";


import VoxBorderButton from "../../components/VoxBorderButton";
import VoxDeleteModal from "../../components/VoxDeleteModal";
import { setTo, setTitle } from "../../store/featrues/data/dataSlice";
import { setLoading } from "../../store/featrues/auth/authSlice";

// Define options for formatting
const dateFormatOptions = { year: 'numeric', month: 'long' };

export default function SignleUserEvent() {

    const { id, eventId } = useParams()

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const [user, setUser] = useState(null)
    const [event, setEvent] = useState(null)
    const [modalOpen, setModalOpen] = useState(false)

    useEffect(() => {
        dispatch(setTo(""))
        dispatch(setTitle("Users"))

        const fetchData = async (id, event_id) => {
            dispatch(setLoading(true))

            try {
                const { data } = await axios.get(`users/${id}`);
                setUser(data)
                const response = await axios.get(`events/${event_id}`)
                setEvent(response?.data)
                dispatch(setLoading(false))
            } catch (error) {
                dispatch(setLoading(false))
            }
        }

        fetchData(id, eventId)
    }, [dispatch, id, eventId])


    const handleConfirmDelete = async () => {
        try {
            const formData = new FormData();
            formData.append("events", JSON.stringify(user?.events?.filter(eID => eID != eventId)))
            formData.append('_method', 'put')

            await axios.postForm(`users/${id}`, formData)
            dispatch(setLoading(false))
            setModalOpen(false)
            navigate(`/users/${id}`)
        } catch (error) {
            dispatch(setLoading(false))
            console.error('Error deleting event:', error);
        }
    }


    return (
        <div className="bg-[#18140c] flex flex-col gap-5 w-full rounded-lg pt-5 pb-10 items-center min-h-[645px]">
            <div className="w-full text-white px-5 justify-between flex items-center border-b border-b-[##5B5549] pb-5 font-quichesans font-normal text-xl">
                {`Events`}

                <VoxBorderButton label={'Cancel'} onClick={() => navigate(`/users/${id}`)} />
            </div>

            <div className="max-w-[690px] w-full pt-10 gap-10 flex flex-col px-5">
                <div className="w-full grid grid-cols-1 mt-8 gap-5">

                    <div className='flex flex-col gap-[10px]'>
                        <div className='uppercase text-xs text-[#897151]'>Event</div>
                        <div className='text-white text-base font-normal font-generalsans leading-6'>{`${event?.location || ""}`}</div>
                    </div>

                    <div className='flex flex-col gap-[10px]'>
                        <div className='uppercase text-xs text-[#897151]'>Date</div>
                        <div className='text-white text-base font-normal font-generalsans leading-6'>{`${event ? new Intl.DateTimeFormat('en-US', dateFormatOptions).format(new Date(event?.date[0]?.startDate)) : ''}`}</div>
                    </div>

                    <div className='flex flex-col gap-[10px]'>
                        <div className='uppercase text-xs text-[#897151]'>ID</div>
                        <div className='text-white text-base font-normal font-generalsans leading-6'>{`${event?.id || "0"}`.padStart(5, '0')}</div>
                    </div>

                </div>

                <div className="w-full flex flex-col gap-5">
                    <div className='flex items-center gap-5'>
                        <VoxBorderButton label={'Unassign Event'} className='!border-[#C51102] !text-[#C51102] text-sm' onClick={() => setModalOpen(true)} />
                        <div className='text-[#C51102] text-sm'>Please Note: This action cannot be undone.</div>
                    </div>

                    <VoxDeleteModal open={modalOpen} setOpen={setModalOpen} btnText={'Unassign Event'} title={'Unassign Event?'} content={'Are you sure? This cannot be undone.'} onConfirm={handleConfirmDelete} />
                </div>
            </div>
        </div>
    )
}



export const formatDateRange = (startDate, endDate) => {
    // Helper function to pad single digit numbers with a leading zero
    const padToTwoDigits = (num) => num.toString().padStart(2, '0');

    // Format startDate
    const startYear = startDate.getFullYear().toString().slice(-2); // Last 2 digits of year
    const startMonth = padToTwoDigits(startDate.getMonth() + 1); // Month is zero-based
    const startDay = padToTwoDigits(startDate.getDate());

    // Format endDate
    const endYear = endDate.getFullYear().toString().slice(-2); // Last 2 digits of year
    const endMonth = padToTwoDigits(endDate.getMonth() + 1); // Month is zero-based
    const endDay = padToTwoDigits(endDate.getDate());

    // Concatenate to desired format
    return `${startDay}/${startMonth}/${startYear} - ${endDay}/${endMonth}/${endYear}`;
};