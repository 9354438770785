import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import axios from "axios";


import VoxBorderButton from "../../components/VoxBorderButton";
import VoxButton from "../../components/VoxButton";
import VoxInput from "../../components/VoxInput";
import VoxSuccess from "../../components/VoxSuccess";
import { setTo, setTitle } from "../../store/featrues/data/dataSlice";
import { setLoading } from "../../store/featrues/auth/authSlice";


export default function AddNewUser() {

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const [name, setName] = useState('')
    const [lname, setLName] = useState('')
    const [email, setEmail] = useState('')
    const [role, setRole] = useState('')

    const [savedSuccess, setSavedSuccess] = useState(false)
    const [errors, setErrors] = useState(null)

    useEffect(() => {
        dispatch(setTo(""))
        dispatch(setTitle("Users"))
    }, [])



    const handleSaveUser = async () => {
        const formData = new FormData();

        formData.append('name', name)
        formData.append('lname', lname)
        formData.append('email', email)
        formData.append('role', role)

        dispatch(setLoading(true))
        try {
            await axios.postForm('auth/register', formData)

            setSavedSuccess(true)
            dispatch(setLoading(false))
        } catch (error) {
            setErrors(error?.response?.data?.data)
            dispatch(setLoading(false))
        }
    }



    return (
        <div className="bg-[#18140c] flex flex-col gap-5 w-full rounded-lg pt-5 pb-10 items-center min-h-[645px]">
            <div className="w-full text-white px-5 justify-between flex items-center border-b border-b-[##5B5549] pb-5 font-quichesans font-normal text-xl">
                {`Add New User`}

                <VoxBorderButton label={'Cancel'} onClick={() => navigate('/users')} />
            </div>
            {
                !savedSuccess &&
                <div className="max-w-[690px] w-full pt-10 gap-10 flex flex-col px-5">
                    <div className="w-full grid grid-cols-1 md:grid-cols-2 mt-8 gap-5">

                        <VoxInput
                            placeholder={'FIRST NAME'}
                            classsName="shadow-none w-full"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            error={errors && errors['name']}
                        />

                        <VoxInput
                            placeholder={'LAST NAME'}
                            classsName="shadow-none w-full"
                            value={lname}
                            onChange={(e) => setLName(e.target.value)}
                            error={errors && errors['lname']}
                        />

                        <VoxInput
                            placeholder={'EMAIL'}
                            classsName="shadow-none w-full"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            error={errors && errors['email']}
                        />

                        <div>
                            <select
                                className="border border-[#C1B7A8] rounded-[5px] py-[15px] px-[20px] flex justify-between text-sm text-white bg-transparent w-full"
                                placeholder="ROLE"
                                onChange={(e) => setRole(e.target.value)}
                                value={role}
                            >
                                <option>Guest</option>
                                <option>Admin</option>
                            </select>
                        </div>

                    </div>

                    <div className="w-full flex flex-col gap-5">

                        <VoxButton label={'Add User'} className="w-max" onClick={handleSaveUser} />
                    </div>
                </div>
            }
            {
                savedSuccess &&
                <VoxSuccess content={'New user successfully added.'} to={'/users'} btnLabel={'Back to users'} />
            }
        </div>
    )
}



export const formatDateRange = (startDate, endDate) => {
    // Helper function to pad single digit numbers with a leading zero
    const padToTwoDigits = (num) => num.toString().padStart(2, '0');

    // Format startDate
    const startYear = startDate.getFullYear().toString().slice(-2); // Last 2 digits of year
    const startMonth = padToTwoDigits(startDate.getMonth() + 1); // Month is zero-based
    const startDay = padToTwoDigits(startDate.getDate());

    // Format endDate
    const endYear = endDate.getFullYear().toString().slice(-2); // Last 2 digits of year
    const endMonth = padToTwoDigits(endDate.getMonth() + 1); // Month is zero-based
    const endDay = padToTwoDigits(endDate.getDate());

    // Concatenate to desired format
    return `${startDay}/${startMonth}/${startYear} - ${endDay}/${endMonth}/${endYear}`;
};