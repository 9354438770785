import { createSlice } from '@reduxjs/toolkit'

export const dataSlice = createSlice({
    name: 'data',
    initialState: {
        title: '', 
        to: null, 
        event: null,
    },
    reducers: {
        setTitle: (state, action) => {
            state.title = action.payload
        },
        setTo: (state, action) => {
            state.to = action.payload
        }, 
        setEvent: (state, action) => {
            state.event = action.payload
        }, 
    },
})

// Action creators are generated for each case reducer function
export const { setTitle, setTo, setEvent } = dataSlice.actions

export default dataSlice.reducer