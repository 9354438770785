import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import axios from "axios";


import VoxBorderButton from "../../components/VoxBorderButton";
import VoxButton from "../../components/VoxButton";
import VoxInput from "../../components/VoxInput";
import VoxTextarea from "../../components/VoxTextarea";
import VoxImagePicker from "../../components/VoxImagePicker";
import VoxSuccess from "../../components/VoxSuccess";
import VoxDeleteModal from "../../components/VoxDeleteModal";
import { setTo, setTitle } from "../../store/featrues/data/dataSlice";
import { setLoading } from "../../store/featrues/auth/authSlice";


export default function AddNewExplore() {

    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { id } = useParams()

    const [explore, setExplore] = useState(null)
    const [image, setImage] = useState(null)
    const [title, setTitleData] = useState('')
    const [category, setCategory] = useState('')
    const [bodycopy, setBodycopy] = useState('')

    const [savedSuccess, setSavedSuccess] = useState(false)
    const [errors, setErrors] = useState(null)
    const [modalOpen, setModalOpen] = useState(false)

    useEffect(() => {
        dispatch(setTo(""))
        dispatch(setTitle("Explore"))

        const fetchExplore = async ( id ) => {
            try {
                const { data } = await axios.get(`explore/${id}`);
                setExplore(data)
                setTitleData(data?.title)
                setCategory(data?.category)
                setBodycopy(data?.bodycopy)
            } catch (error) {
                
            }
        }


        if( id ) {
            fetchExplore(id)
        }
    }, [id])




    const handleSaveExplore = async () => {
        const formData = new FormData();

        formData.append("file", image)
        formData.append("title", title)
        formData.append("category", category)
        formData.append("bodycopy", bodycopy)

        dispatch(setLoading(true))
        try {
            if( id ) {
                formData.append('_method', 'put');
                await axios.postForm(`explore/${id}`, formData)
            } else {
                await axios.postForm('explore', formData)
            }
            setSavedSuccess(true)
            dispatch(setLoading(false))
        } catch (error) {
            setErrors(error?.response?.data?.data)
            dispatch(setLoading(false))
        }
    }

    const handleConfirmDelete = async () => {
        try {
            await axios.delete(`explore/${id}`)
            setModalOpen(false)
            navigate('/explore')
        } catch (error) {
            console.log(error)
        }
    }


    return (
        <div className="w-full flex flex-col gap-[30px]">
            <div className="bg-[#18140c] flex flex-col gap-5 w-full rounded-lg pt-5 pb-10 items-center min-h-[645px]">
                <div className="w-full text-white px-5 justify-between flex items-center border-b border-b-[##5B5549] pb-5 font-quichesans font-normal text-xl">
                    { id ? `Edit Article` : `Add New Article`}

                    <VoxBorderButton label={'Cancel'} onClick={() => navigate('/explore')} />
                </div>
                {
                    !savedSuccess &&
                    <div className="max-w-[690px] w-full pt-10 gap-5 flex flex-col px-5">
                        <div className="text-xl text-white font-quichesans font-normal">Article Details</div>

                        <VoxImagePicker
                            label={'add feature image'}
                            setFile={setImage}
                            file={image}
                            error={errors && errors['file']}
                            url={explore?.image}
                        />

                        <VoxInput
                            placeholder={'TITLE'}
                            classsName="shadow-none w-full"
                            value={title}
                            onChange={(e) => setTitleData(e.target.value)}
                            error={errors && errors['title']}
                        />

                        <VoxInput
                            placeholder={'CATEGORY'}
                            classsName="shadow-none w-full"
                            value={category}
                            onChange={(e) => setCategory(e.target.value)}
                            error={errors && errors['category']}
                        />

                        <VoxTextarea
                            placeholder={'BODY COPY'}
                            classsName="w-full"
                            rows={5}
                            value={bodycopy}
                            onChange={(e) => setBodycopy(e.target.value)}
                            error={errors && errors['bodycopy']}
                        />

                        <VoxButton label={id ? 'Save' : 'Add Article'} className="w-max mt-5" onClick={handleSaveExplore} />

                    </div>
                }
                {
                    savedSuccess &&
                    <VoxSuccess content={id ? 'Article successfully edited.' : 'New article successfully added.'} to={'/explore'} btnLabel={'Back to explore'} />
                }
            </div>
            
            {
                id && 
                <div className='flex items-center gap-5'>
                    <VoxBorderButton label={'Delete Article'} className='!border-[#C51102] !text-[#C51102] text-sm' onClick={() => setModalOpen(true)} />
                    <div className='text-[#C51102] text-sm'>Please Note: This action cannot be undone.</div>
                </div>
            }

            <VoxDeleteModal open={modalOpen} setOpen={setModalOpen} btnText={'Delete Article'} title={'Delete Article?'} content={'Are you sure? This cannot be undone.'} onConfirm={handleConfirmDelete} />
        </div>
    )
}



export const formatDateRange = (startDate, endDate) => {
    // Helper function to pad single digit numbers with a leading zero
    const padToTwoDigits = (num) => num.toString().padStart(2, '0');

    // Format startDate
    const startYear = startDate.getFullYear().toString().slice(-2); // Last 2 digits of year
    const startMonth = padToTwoDigits(startDate.getMonth() + 1); // Month is zero-based
    const startDay = padToTwoDigits(startDate.getDate());

    // Format endDate
    const endYear = endDate.getFullYear().toString().slice(-2); // Last 2 digits of year
    const endMonth = padToTwoDigits(endDate.getMonth() + 1); // Month is zero-based
    const endDay = padToTwoDigits(endDate.getDate());

    // Concatenate to desired format
    return `${startDay}/${startMonth}/${startYear} - ${endDay}/${endMonth}/${endYear}`;
};