import Modal from 'react-modal';
import VoxBorderButton from './VoxBorderButton';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',

        backgroundColor: '#18140C', 
        border: 'none', 
        padding: '20px', 
        position: 'relative', 
        width: '416px'
    },
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.8)', 
    }
};

Modal.setAppElement('#root');

export default function VoxDeleteModal({ open, setOpen, title, content = "Are you sure? This cannot be undone.", btnText, onConfirm }){

    return (
        <div>
            <Modal
                isOpen={open}
                onRequestClose={() => setOpen(false)}
                style={customStyles}
                contentLabel="Example Modal"
            >
                <div className='relative w-full flex flex-col gap-5 items-center'>
                    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" className='right-0 absolute top-0 cursor-pointer' onClick={() => setOpen(false)}>
                        <path fillRule="evenodd" clipRule="evenodd" d="M0.29289 0.29289C0.68342 -0.09763 1.31658 -0.09763 1.70711 0.29289L7 5.5858L12.2929 0.29289C12.6834 -0.09763 13.3166 -0.09763 13.7071 0.29289C14.0976 0.68342 14.0976 1.31658 13.7071 1.70711L8.4142 7L13.7071 12.2929C14.0976 12.6834 14.0976 13.3166 13.7071 13.7071C13.3166 14.0976 12.6834 14.0976 12.2929 13.7071L7 8.4142L1.70711 13.7071C1.31658 14.0976 0.68342 14.0976 0.29289 13.7071C-0.09763 13.3166 -0.09763 12.6834 0.29289 12.2929L5.5858 7L0.29289 1.70711C-0.09763 1.31658 -0.09763 0.68342 0.29289 0.29289Z" fill="white"/>
                    </svg>
                    
                    <div className='w-[50px] h-[50px] bg-[#C51102] bg-opacity-10 rounded-lg flex justify-center items-center'>
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd" d="M5 2C5 0.89543 5.89543 0 7 0H13C14.1046 0 15 0.89543 15 2V4H16.9897C16.9959 3.99994 17.0021 3.99994 17.0083 4H19C19.5523 4 20 4.44772 20 5C20 5.55228 19.5523 6 19 6H17.9311L17.0638 18.1425C16.989 19.1891 16.1182 20 15.0689 20H4.93112C3.88184 20 3.01096 19.1891 2.9362 18.1425L2.06888 6H1C0.44772 6 0 5.55228 0 5C0 4.44772 0.44772 4 1 4H2.99174C2.99795 3.99994 3.00414 3.99994 3.01032 4H5V2ZM7 4H13V2H7V4ZM4.07398 6L4.93112 18H15.0689L15.926 6H4.07398ZM8 8C8.5523 8 9 8.4477 9 9V15C9 15.5523 8.5523 16 8 16C7.44772 16 7 15.5523 7 15V9C7 8.4477 7.44772 8 8 8ZM12 8C12.5523 8 13 8.4477 13 9V15C13 15.5523 12.5523 16 12 16C11.4477 16 11 15.5523 11 15V9C11 8.4477 11.4477 8 12 8Z" fill="#C51102"/>
                        </svg>
                    </div>

                    <div className='text-white text-[25px] leading-[31px] font-normal'>{title}</div>
                    <div className='text-white text-base font-normal'>{ content }</div>

                    <VoxBorderButton className="!border-[#C51102] !text-[#C51102] text-sm" label={btnText} onClick={onConfirm} />
                </div>
            </Modal>
        </div>
    )
}