import { createSlice } from '@reduxjs/toolkit'

export const authSlice = createSlice({
    name: 'auth',
    initialState: {
        authToken: localStorage.getItem('authToken') || null, 
        user: null,
        loading: false
    },
    reducers: {
        setAuthToken: (state, action) => {
            state.authToken = action.payload
        },
        setUser: (state, action) => {
            state.user = action.payload
        }, 
        setLoading: (state, action) => {
            state.loading = action.payload
        }
    },
})

// Action creators are generated for each case reducer function
export const { setAuthToken, setUser, setLoading } = authSlice.actions

export default authSlice.reducer