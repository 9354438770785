import { useState } from 'react';
import { Card } from 'primereact/card';
import axios from 'axios';
import { useParams, useLocation, useNavigate } from 'react-router-dom';

import VoxInput from '../../components/VoxInput';
import VoxButton from '../../components/VoxButton';

import logo from "../../assets/svg/logo-l.svg";
import logoS from "../../assets/svg/logo-s.svg";

const useQuery = () => {
    return new URLSearchParams(useLocation().search);
};

export default function CreateNewPassword({ params }) {
    const query = useQuery();
    const navigate = useNavigate()

    const id = query.get('id');
    const { token } = useParams();
    const [password, setPassword] = useState("")
    const [passwordConfirm, setPasswordConfirm] = useState("")
    const [error, setError] = useState("")

    const handleResetPassword = async () => {
        setError("")
        try {
            await axios.post('auth/password-reset', {
                password,
                password_confirmation: passwordConfirm,
                token,
                id
            })
            navigate('/success-reset-password')
        } catch (error) {
            setError(error?.response?.data?.message)
        }
    }



    return (
        <div className='min-h-[100vh] w-full flex justify-center items-center bg-[#070603] py-10'>
            <Card className='bg-[#18140c] lg:px-[120px] lg:py-[166px] sm:px-[60px] sm:py-[30px] px-[20px] py-[40px] rounded-lg '>
                <div className='flex flex-col gap-[60px] lg:gap-[275px] lg:flex-row sm:px-[126px] sm:pb-[80px] sm:lg:px-0 lg:pb-0'>
                    <div className='flex flex-col gap-8 sm:w-[335px] w-[295px]'>
                        <div className='flex flex-col gap-5'>
                            <div className='header4 text-[25px] leading-[31px]'>Create a new password</div>
                            <div className='content4 text-base'>Enter your new password below. </div>
                        </div>
                        <div className='flex flex-col gap-5'>
                            <VoxInput
                                placeholder="ENTER NEW PASSWORD"
                                classsName='w-full'
                                type='password'
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                            <VoxInput
                                placeholder="CONFIRM NEW PASSWORD"
                                classsName='w-full'
                                type='password'
                                value={passwordConfirm}
                                onChange={(e) => setPasswordConfirm(e.target.value)}
                            />

                            {
                                error &&
                                <div className='text-red-500 text-base'>{error}</div>
                            }
                        </div>

                        <div className='flex flex-col gap-5'>
                            <VoxButton label='Reset Password' onClick={() => handleResetPassword()} />
                        </div>
                    </div>

                    <img src={logo} alt="logo-image" className='hidden sm:block' />
                    <img src={logoS} alt="logo-image" className='sm:hidden block' />
                </div>
            </Card>
        </div>
    )
}