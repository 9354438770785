import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";


import VoxBorderButton from "../components/VoxBorderButton";
import VoxButton from "../components/VoxButton";
import VoxInput from "../components/VoxInput";
import VoxSuccess from "../components/VoxSuccess";
import { setTo, setTitle } from "../store/featrues/data/dataSlice";
import { setLoading, setUser } from "../store/featrues/auth/authSlice";


export default function Account() {

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const { user } = useSelector((state) => state.auth)

    const [name, setName] = useState(user?.name)
    const [lname, setLName] = useState(user?.lname)
    const [email, setEmail] = useState(user?.email)
    const [password, setPassword] = useState('')
    const [confirm, setConfirm] = useState('')

    const [readable, setReadable] = useState(true)
    const [savedSuccess, setSavedSuccess] = useState(false)
    const [errors, setErrors] = useState(null)

    useEffect(() => {
        dispatch(setTo(""))
        dispatch(setTitle("My Account"))
    }, [])

    useEffect(() => {
        setName(user?.name)
        setLName(user?.lname)
        setEmail(user?.email)
    }, [user])



    const handleSaveUser = async () => {
        let isError = false;
        if( name === "" ) {
            setErrors((pre) => ({...pre, name: 'Name field required.'}))
            isError = true
        }
        if( email === "" ) {
            setErrors((pre) => ({...pre, email: 'Email field required.'}))
            isError = true
        }

        if( isError ) {
            return;
        }

        const formData = new FormData();

        formData.append('name', name)
        formData.append('lname', lname)
        formData.append('email', email)
        formData.append('password', password)
        formData.append('password_confirmation', confirm)
        formData.append('_method', 'put')

        dispatch(setLoading(true))
        try {
            await axios.postForm(`users/${user?.id}`, formData)

            dispatch(setUser({...user, name, lname, email}))
            setSavedSuccess(true)
            dispatch(setLoading(false))
            setErrors(null)
        } catch (error) {
            setErrors(error?.response?.data?.data)
            dispatch(setLoading(false))
        }
    }



    return (
        <div className="bg-[#18140c] flex flex-col gap-5 w-full rounded-lg pt-5 pb-10 items-center min-h-[645px]">
            <div className="w-full text-white px-5 justify-between flex items-center border-b border-b-[##5B5549] pb-5 font-quichesans font-normal text-xl">
                {`Account Details - ${ `${user?.id}`.padStart(5, '0') }`}

                <VoxBorderButton label={'Edit'} onClick={() => setReadable(false)} />
            </div>
            {
                !savedSuccess &&
                <div className="max-w-[690px] w-full pt-10 gap-10 flex flex-col px-5">
                    <div className="w-full grid grid-cols-1 md:grid-cols-2 mt-8 gap-5">

                        <VoxInput
                            placeholder={'FIRST NAME'}
                            classsName="shadow-none w-full"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            error={errors && errors['name']}
                            readonly={readable}
                        />

                        <VoxInput
                            placeholder={'LAST NAME'}
                            classsName="shadow-none w-full"
                            value={lname}
                            onChange={(e) => setLName(e.target.value)}
                            error={errors && errors['lname']}
                            readonly={readable}
                        />

                        <VoxInput
                            placeholder={'EMAIL'}
                            classsName="shadow-none w-full"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            error={errors && errors['email']}
                            readonly={readable}
                        />

                        <VoxInput
                            placeholder={'PASSWORD'}
                            classsName="shadow-none w-full"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            error={errors && errors['password']}
                            readonly={readable}
                            type={'password'}
                        />

                        {
                            !readable &&
                            <div className="md:col-span-2">
                                <VoxInput
                                    placeholder={'CONFIRM NEW PASSWORD'}
                                    classsName="shadow-none w-full"
                                    value={confirm}
                                    onChange={(e) => setConfirm(e.target.value)}
                                    readonly={readable}
                                    type={'password'}
                                />
                            </div>
                        }

                    </div>

                    <div className="w-full flex flex-col gap-5">

                        <VoxButton label={'Save Changes'} className="w-max" onClick={handleSaveUser} />
                    </div>
                </div>
            }
            {
                savedSuccess &&
                <VoxSuccess content={'Account information successfully updated.'} btnLabel={'Back to account'} onClick={() => {
                    setReadable(true)
                    setPassword('')
                    setConfirm('')
                    setSavedSuccess(false)
                }} />
            }
        </div>
    )
}



export const formatDateRange = (startDate, endDate) => {
    // Helper function to pad single digit numbers with a leading zero
    const padToTwoDigits = (num) => num.toString().padStart(2, '0');

    // Format startDate
    const startYear = startDate.getFullYear().toString().slice(-2); // Last 2 digits of year
    const startMonth = padToTwoDigits(startDate.getMonth() + 1); // Month is zero-based
    const startDay = padToTwoDigits(startDate.getDate());

    // Format endDate
    const endYear = endDate.getFullYear().toString().slice(-2); // Last 2 digits of year
    const endMonth = padToTwoDigits(endDate.getMonth() + 1); // Month is zero-based
    const endDay = padToTwoDigits(endDate.getDate());

    // Concatenate to desired format
    return `${startDay}/${startMonth}/${startYear} - ${endDay}/${endMonth}/${endYear}`;
};